body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .side-side-panel__header{
  display: none !important;
}
.side-side-panel__header__bottom{
  display: none !important;
}
.add-data-button{
  display: none !important;
}
.layer-manager {
  display: none !important;
} */
#range{
  position: absolute;
  bottom:70px;
  left: 50%;
  width: 400px;
  display: none;
}
#range input {
  width: 100%;
}

/* The custom-radio-btn-container */
.custom-radio-btn-container {
  color: white;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio-btn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio-btn-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-radio-btn-container input:checked ~ .checkmark {
  background-color: #686868;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-btn-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-btn-container .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}